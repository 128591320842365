import { Button } from "@/components/ui/button";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { SelectWithAlert } from "@/components/ui/select-with-alert";
import { toast } from "@/components/ui/use-toast";
import { REPRESENTATIVE_COMPANY_LIST, transformFromIdValueToValueLabel } from "@/constants";
import { bankUpload, getBankReportXLSX, getInvoicesForReport, getReport, getTransactionsForReport, getUser, performBankReportCheck } from "@/service/weexpertService";
import { Cross1Icon, FileIcon } from "@radix-ui/react-icons";
import { default as React, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";


const FileSelector = (props) => {
  const { t } = useTranslation();
  const { setFile, file, id } = props;
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (file) {
      toast({
        title: t("alerts:success_wait_for_download"),
        description: t("alerts:success_wait_for_download"),
      })
      bankUpload(id, file)
        .then((response) => {
          if (response.status === 200) {
            toast({
              title: t("alerts:succes"),
              description: t("alerts:succes_upload"),
            })
            setFile(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }
        })
        .catch((err) => {
          toast({
            title: t("alerts:toast_error_counterparty_title"),
            description: "alerts:toast_error_counterparty_description",
            variant: "destructive"
          })
        })
    }
  };

  return (
    <div className="w-full flex justify-between pt-4">
      <div>
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          className="block text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                   file:rounded-sm file:border-0 file:text-sm file:font-semibold
                   file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
        />
      </div>
      <div>
        <Button className={file ? `text-sm` : `bg-gray-500 hover:bg-gray-500`} onClick={handleFileUpload}>
          <div className="text-sm">Upload mbank file</div>
          <div className=""><FileIcon className="w-3 h-3 ml-4" /></div>
        </Button>
      </div>
    </div>
  );
};

import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { DataTablePagination } from "@/components/ui/table-pagination";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";
import { v4 as uuidv4 } from 'uuid';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { format } from "date-fns";
import { BookCheck, BookX, CheckIcon, Cross, CrossIcon, Loader, XIcon } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { BatchExplanationCreate } from "@/components/popups/batch-explanation.popup";
import { useDebounce } from "@/components/ui/multiple-selector";


const ReportInvoiceTable = (props) => {
  const { reportId, fetchFunction, cacheKey } = props;
  const { t } = useTranslation()
  const columns = [
    {
      accessorKey: "id",
      header: t("invoices:document"),
      cell: ({ row }) => {
        return <div className="inline-block w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"block w-full h-full"}>
            {row.original.invoiceId}
          </Link>
        </div>
      },
      key: uuidv4()
    },
    {
      accessorKey: "client",
      header: t("common:client"),
      cell: ({ row }) => (
        <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>

          {row.original.client.firstName} {row.original.client.lastName}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "counterparty",
      header: t("common:counterparty"),
      cell: ({ row }) => (
        <div className="capitalize">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {row.original.counterparty}
          </Link>
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "date of creation",
      header: t("invoices:date_of_creation"),
      cell: ({ row }) => {
        return <div className="inline-block w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {format(new Date(row.original.createdAt), 'dd.MM.yyyy')}
          </Link>
        </div>
      },
      key: uuidv4()
    },
    {
      accessorKey: "date of sale",
      header: t("invoices:date_of_sale"),
      cell: ({ row }) => {
        return <div className="inline-block w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {format(new Date(row.original.domainDate), 'dd.MM.yyyy')}
          </Link>
        </div>
      },
      key: uuidv4()
    },
    {
      accessorKey: "total brutto",
      header: t("invoices:price_brutto"),
      cell: ({ row }) => {
        return <div className="inline-block	w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {parseFloat(row.original.invoiceBrutto).toFixed(2)} {row.original.paymentCurrency}
          </Link>
        </div>
      },
      key: uuidv4()
    }
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });

  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
  }, [pagination]);

  const { data: transactionsData, isLoading } = useQuery({
    queryKey: ['t_____', 'invoices___', reportId, page, cacheKey],
    retry: false,
    queryFn: () => {
      return fetchFunction(reportId, rowsPerPage, page * rowsPerPage)
    }
  });

  const table = useReactTable({
    data: isLoading ? Array(30).fill({}) : transactionsData?.data.invoices,
    columns: isLoading ? columns.map(column => {
      return {
        ...column,
        cell: () => <Skeleton className={"h-4 w-[150px]"} />
      }
    }) : columns,
    rowCount: transactionsData?.data.total ?? 0,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      pagination
    },
    initialState: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="relative">
        <div className="sticky left-0  z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between  pr-8 items-center bg-grayLightMainBg">
            <div className="flex justify-end w-5/6">
            </div>
          </div>
        </div>
        <div className="w-full bg-grayLightMainBg">
          <div className="rounded-md border p-2">
            {(
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        {t('no_results')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  );

}

const TransactionsTable = (props) => {

  const { translateForTransactionsTable } = props
  const { reportId, fetchFunction, cacheKey } = props;
  const get_view_url = (id) => {
    return `/report/transaction/${id}`
  }
  const [selectedTransaction, setSelectedTransaction] = useState([])
  const handleCheckboxChange = (id) => {
    if (selectedTransaction.includes(id)) {
      setSelectedTransaction((rev) => {
        return rev.filter(i => i != id)
      })
      return
    }
    setSelectedTransaction((rev) => {
      return rev.concat(id);
    })
  }
  const handleSelectAllChange = () => {
    if (selectedTransaction.length == transactionsData?.data.transactions.length) {
      setSelectedTransaction([])
      return
    }
    setSelectedTransaction(transactionsData?.data.transactions.map((item) => item.id));
  };


  const columns = [
    {
      id: "select",
      header: () => (
        <Checkbox className='flex flex-row items-center justify-center py-0'
          checked={selectedTransaction.length === transactionsData?.data?.transactions?.length && transactionsData.length !== 0}
          onCheckedChange={() => handleSelectAllChange()}
          aria-label="Select all"
        />
      ),

      cell: ({ row }) => (
        <Checkbox className='flex flex-row items-center justify-center py-0'
          checked={selectedTransaction.includes(row.original.id)}
          onCheckedChange={() => handleCheckboxChange(row.original.id)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false
    },

    {
      accessorKey: "id",
      header: translateForTransactionsTable('reports:transaction_ID'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={get_view_url(row.original.id)}>
          {row.original.transactionID}
        </Link>
      ),
    },
    {
      accessorKey: "amount",
      header: translateForTransactionsTable('invoices:price_brutto'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={get_view_url(row.original.id)}>
          {row.original.amount}
        </Link>
      ),
    },
    {
      accessorKey: "currency",
      header: translateForTransactionsTable('invoices:currency'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={get_view_url(row.original.id)}>
          {row.original.currency}
        </Link>
      ),
    },
    {
      accessorKey: "Account",
      header: translateForTransactionsTable('invoices:payment_account'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={get_view_url(row.original.id)}>
          {row.original.accountNumber}
        </Link>
      ),
    },
    {
      accessorKey: "Booking_date",
      header: translateForTransactionsTable('reports:booking_date'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={get_view_url(row.original.id)}>
          {format(new Date(row.original.bookingDate ?? null), 'dd.MM.yyyy')}
        </Link>
      ),
    },
    {
      accessorKey: "Coounerparty",
      header: translateForTransactionsTable('counterparty'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={get_view_url(row.original.id)}>
          {row.original.contractor}
        </Link>
      ),
    },

    {
      accessorKey: "Matched",
      header: translateForTransactionsTable('reports:matched'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={get_view_url(row.original.id)}>
          {row.original.matched ? <BookCheck /> : <BookX />}
        </Link>
      ),
    },
    {
      accessorKey: "Explanation",
      header: translateForTransactionsTable('reports:explanation'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={get_view_url(row.original.id)}>
          {row.original.bankRecordTransactionExplanation?.length > 0 ? <CheckIcon /> : <XIcon />}
        </Link>
      ),
    },
  ]

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });

  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [filteredAccount, setFilteredAccount] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
  }, [pagination]);

  const { data: transactionsData, isLoading } = useQuery({
    queryKey: ['report_____', 'transactions___', reportId, page, cacheKey, debouncedSearchQuery, filteredAccounts],
    retry: false,
    queryFn: () => fetchFunction(reportId, rowsPerPage, page * rowsPerPage, debouncedSearchQuery, filteredAccounts.length > 0 ? [{ field: 'account', value: [filteredAccounts] }] : [])
  })

  const table = useReactTable({
    data: isLoading ? Array(30).fill({}) : transactionsData?.data.transactions,
    columns: columns,
    rowCount: transactionsData?.data.total ?? 0,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      pagination
    },
    initialState: {
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const filterResult = filteredAccounts.map((e) => (
    <Badge key={uuidv4()} className={"bg-green-800 col-span-1 py-3 hover:bg-green-800"} >
      <div className="w-full flex justify-between items-center">
        {e}< Cross1Icon className="cursor-pointer" onClick={() => {
          setFilteredAccounts((prev) => prev.filter((i) => i !== e));
        }} />
      </div>
    </Badge>
  ));

  const handleAddBadge = () => {
    const trimmedValue = filteredAccount.trim();
    if (trimmedValue !== "" && !filteredAccounts.includes(trimmedValue)) {
      setFilteredAccounts((prev) => [...prev, trimmedValue]);
      setFilteredAccount('');
    }
  };

  return (
    <>
      <>
        <div className="grid grid-flow-row-dense grid-cols-6 gap-4 mb-4">
          <Input className="col-span-12"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={translateForTransactionsTable('search')}
          />
        </div>
      </>
      <>
        <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
          <Input className="col-span-2"
            placeholder={translateForTransactionsTable("reports:filter_account")}
            value={filteredAccount}
            onChange={(e) => { setFilteredAccount(e.target.value) }}
          />
          <Button className={`col-span-1 ${(filteredAccounts.length === 0 && filteredAccount.trim() === "") ? 'bg-gray-500 hover:bg-gray-500' : ''}`} onClick={handleAddBadge}>{translateForTransactionsTable('button_add')}</Button>
          {selectedTransaction.length > 0 &&
            <div className="w-[400px]">
              <BatchExplanationCreate ids={selectedTransaction} />
            </div>
          }
        </div>
        <div className="grid grid-flow-row-dense grid-cols-6 gap-x-2 gap-y-2 mt-4 mb-4">
          {filterResult}
        </div>
      </>
      <div className="relative">
        <div className="sticky left-0  z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between  pr-8 items-center bg-grayLightMainBg">
            <div className="flex justify-end w-5/6">
            </div>
          </div>
        </div>
        <div className="w-full bg-grayLightMainBg">
          <div className="rounded-md border p-2">
            {(
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        {translateForTransactionsTable('no_results')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  );
}

const RelatedTalbes = (props) => {
  const { t } = useTranslation();
  const { raportId } = props;

  return (
    <>
      <Tabs defaultValue="unmatched_transaction" >
        <div className="relative">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between py-8 items-center bg-grayLightMainBg">
              <div className="flex justify-end w-full">
                <TabsList className="grid w-full grid-cols-5">
                  <TabsTrigger value="unmatched_transaction">{t('reports:unmatched_transaction')}</TabsTrigger>
                  <TabsTrigger value="unmatched_invoices">{t('reports:unmatched_invoices')}</TabsTrigger>
                  <TabsTrigger value="matched_transaction">{t('reports:matchet_transaction')}</TabsTrigger>
                  <TabsTrigger value="matched_invoices">{t('reports:matched_invoices')}</TabsTrigger>
                  <TabsTrigger value="transactions">{t('reports:report_transactions')}</TabsTrigger>
                </TabsList>
              </div>
            </div>
          </div>
          <div className="w-full  bg-grayLightMainBg">
            <TabsContent value="transactions">
              <TransactionsTable translateForTransactionsTable={t} reportId={raportId} fetchFunction={(reportId, take, skip, search) => {
                return getTransactionsForReport(reportId, take, skip, search, [])
              }}
                cacheKey='transaction'
              />
            </TabsContent>
            <TabsContent value="unmatched_transaction">
              <TransactionsTable translateForTransactionsTable={t} reportId={raportId}
                fetchFunction={(reportId, take, skip, search, filters = []) => {
                  return getTransactionsForReport(reportId, take, skip, search, [{ field: 'matched', value: ['false'] }].concat(filters))
                }}
                cacheKey='unmatched_transaction'
              />
            </TabsContent>
            <TabsContent value="matched_transaction">
              <TransactionsTable translateForTransactionsTable={t} reportId={raportId}
                fetchFunction={(reportId, take, skip, search, filters = []) => {
                  return getTransactionsForReport(reportId, take, skip, search, [{ field: 'matched', value: ['true'] }].concat(filters))
                }}
                cacheKey='matched_transaction'
              />
            </TabsContent>
            <TabsContent value="matched_invoices">
              <ReportInvoiceTable reportId={raportId} fetchFunction={(reportId, take, skip) => {
                return getInvoicesForReport(reportId, take, skip, null, [], 'matched')
              }}
                cacheKey='invoice_alq'
              />
            </TabsContent>
            <TabsContent value="unmatched_invoices">
              <ReportInvoiceTable reportId={raportId} fetchFunction={(reportId, take, skip) => {
                return getInvoicesForReport(reportId, take, skip, null, [], 'unmatched')
              }}
                cacheKey='invoice_all'
              />
            </TabsContent>
          </div>
        </div>
      </Tabs>
    </>
  );
}

const ReportViewPage = () => {

  const { t } = useTranslation();
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const navigate = useNavigate()
  const { data: report, isLoading } = useQuery({
    queryKey: ['report', id],
    queryFn: () => getReport(id),
    retry: false
  });

  const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false });

  const reportData = report?.data?.report;
  const userRole = user?.data.roles
  if (userRole === 'CLIENT') {
    navigate('/404')
  }
  return (
    <>
      {isUserLoading ?
        <Loader />
        :
        (
          <div className="w-full relative bg-grayLightMainBg px-8">
            <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
              <div className="flex justify-between pt-8  items-center ">
                <div className=" w-5/12  text-4xl ">
                  {t('reports:report_title') + ':'} {id}
                </div>
                <div>
                  <div className="flex justify-between">
                    <Button className="mr-4"
                      onClick={() => {
                        toast({
                          title: "wait for check to be performed"
                        }
                        )
                        getBankReportXLSX(id)
                          .then(() => {
                            toast({
                              title: t("alerts:toast_succes_invoice_create_description"),
                              description: t("alerts:toast_succes_invoice_create_description"),
                            })
                          })
                      }}
                    >
                      {t('reports:download_report')}
                    </Button>

                    <Button className="mr-4"
                      onClick={() => {
                        toast({
                          title: "wait for check to be performed"
                        }
                        )
                        performBankReportCheck(id)
                          .then((response) => {
                            if (response.status === 200) {
                              toast({
                                title: t("alerts:toast_succes_invoice_create_description"),
                                description: t("alerts:toast_succes_invoice_create_description"),
                              })
                            }
                          })
                          .catch((error) => {
                            toast({
                              title: t("alerts:toast_error_counterparty_title"),
                              description: t("alerts:toast_error_invoice_description"),
                              variant: "destructive"
                            })
                          })

                      }}
                    >{t('reports:perform_match')}</Button>
                    <Link to="/reports" className="mr-4"><Button className="min-w-20">{t("button_back")}</Button></Link>
                  </div>
                </div>
              </div>
            </div>
            {!isLoading && <>
              <div className="pt-16">
                <div className="w-4/12 pb-4">
                  <InputWithAlert
                    label={t('counterparties:name')}
                    value={reportData.title}
                    disabled={true}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <InputWithAlert
                    label={t('reports:date_from')}
                    value={format(new Date(reportData.fromDate), 'dd.MM.yyyy')}
                    disabled={true}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <InputWithAlert
                    label={t('reports:date_to')}
                    value={format(new Date(reportData.toDate), 'dd.MM.yyyy')}
                    disabled={true}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <SelectWithAlert
                    label={t('clients:representative_company')}
                    value={reportData.representativeCompany}
                    options={REPRESENTATIVE_COMPANY_LIST.map(transformFromIdValueToValueLabel)}
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    disabled={true}
                  />
                </div>
              </div>
              {(userRole !== "ASSISTANT" && userRole !== "CLIENT") && (
                <div className="w-4/12 pt-2 pb-2 ">
                  <FileSelector
                    setFile={setFile}
                    file={file}
                    id={id}
                  />
                </div>
              )}
              <RelatedTalbes raportId={id} />
            </>
            }
          </div>
        )}
    </>

  )
}
export default ReportViewPage;
